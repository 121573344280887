/*
 * @Author: chen wei
 * @Date: 2023-03-02 11:30:36
 * @LastEditTime: 2023-03-02 17:18:30
 * @Description: 
 */
import React,{ Fragment } from 'react'
import Myself from './pages/myself'
import Effect from './pages/effect'
import Intro from './pages/introduction'
import Sattelit from './pages/sattlite'
import ExEditor from './pages/exEditor'
import { Route, Routes, Navigate,HashRouter } from "react-router-dom";

function App() {
  return (
    <Fragment>
      <HashRouter>
        <Routes>
          <Route path='/' element={<Myself />} />
          <Route path='Effect' element={<Effect />} />
          <Route path='intro' element={<Intro />} />
          <Route path='satellite' element={<Sattelit />} />
          <Route path='Editor' element={<ExEditor />} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
      </HashRouter>
    </Fragment>
  )
}

export default App