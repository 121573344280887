import React,{ Fragment, useEffect } from "react"
import "./effect.less"

function Effect(){
    useEffect(() => {
        console.log('DidMount 和 DidUpdate')
        new window.CloudRenderApi.Scene('myframe', {
            sceneID: "1077617867718918144", //200
            server: "http://192.168.102.200:8181", // 本地服务地址
            saas: true
        })
    })
    return (
        <Fragment>
            <div className={"luy-out"}>
                <iframe id="myframe" title="si"></iframe>
            </div>
        </Fragment>
    )
}
export default Effect