import {getPosition, getPolygon} from './satelliteUtil'
const Cesium = window.Cesium
class PyramidEntityF {
  constructor (viewer, positions) {
    this._entities = []
    this._dynamicEntities = []
    this._viewer = viewer
    this._positions = positions
    this.addEntity()
    this._tickNumber = 0
    this._dynamicNumber = 0
  }
  addEntity () {
    for (let i = 0; i < 7; i = i + 2) {
      const entity2 = this._viewer.entities.add({
        polygon: {
          hierarchy: new Cesium.CallbackProperty(() => {
            const polygon = this.getPolygon()
            const positionNow = getPosition(this._viewer.clock.currentTime, this._positions)
            const position1 = Cesium.Cartesian3.fromDegrees(positionNow.longitude, positionNow.latitude, positionNow.height)
            const position2 = Cesium.Cartesian3.fromDegrees(polygon[i], polygon[i + 1], 0)
            const position3 = Cesium.Cartesian3.fromDegrees(polygon[i + 2], polygon[i + 3], 0)
            return [position1, position2, position3]
          }, false),
          perPositionHeight: true,
          material: Cesium.Color.WHITE.withAlpha(0.2),
          shadows: 1
        }
      })
      this._entities.push(entity2)
    }
    this.addDynamicEntity(16)
  }
  addDynamicEntity (count) {
    for (let i = 1; i < count; i++) {
      const entity = this._viewer.entities.add({
        polygon: {
          hierarchy: new Cesium.CallbackProperty(() => {
            const polygon = this.getPolygon()
            const positionNow = getPosition(this._viewer.clock.currentTime, this._positions)
            const point1 = Cesium.Cartesian3.fromDegrees(polygon[0], polygon[1], positionNow.height * (i / count))
            const point2 = Cesium.Cartesian3.fromDegrees(polygon[2], polygon[3], positionNow.height * (i / count))
            const point3 = Cesium.Cartesian3.fromDegrees(polygon[4], polygon[5], positionNow.height * (i / count))
            const point4 = Cesium.Cartesian3.fromDegrees(polygon[6], polygon[7], positionNow.height * (i / count))
            let lerp1 = new Cesium.Cartesian3()
            Cesium.Cartesian3.lerp(point1, point2, i / (count * 2), lerp1)
            let lerp2 = new Cesium.Cartesian3()
            Cesium.Cartesian3.lerp(point1, point2, ((count * 2) - i) / (count * 2), lerp2)
            let lerp3 = new Cesium.Cartesian3()
            Cesium.Cartesian3.lerp(point3, point4, i / (count * 2), lerp3)
            let lerp4 = new Cesium.Cartesian3()
            Cesium.Cartesian3.lerp(point3, point4, ((count * 2) - i) / (count * 2), lerp4)
            let newPoint1 = new Cesium.Cartesian3()
            Cesium.Cartesian3.lerp(lerp2, lerp3, i / (count * 2), newPoint1)
            let newPoint2 = new Cesium.Cartesian3()
            Cesium.Cartesian3.lerp(lerp2, lerp3, ((count * 2) - i) / (count * 2), newPoint2)
            let newPoint3 = new Cesium.Cartesian3()
            Cesium.Cartesian3.lerp(lerp4, lerp1, i / (count * 2), newPoint3)
            let newPoint4 = new Cesium.Cartesian3()
            Cesium.Cartesian3.lerp(lerp4, lerp1, ((count * 2) - i) / (count * 2), newPoint4)
            return [newPoint1, newPoint2, newPoint3, newPoint4]
          }, false),
          perPositionHeight: true,
          material: Cesium.Color.fromCssColorString('#0ab8f6').withAlpha(0.3),
          shadows: 1
        },
        show: false
      })
      this._dynamicEntities.unshift(entity)
    }
    this._viewer.clock.onTick.addEventListener(this.tick, this)
  }
  tick () {
    if (this._tickNumber % 10 === 0) {
      const mod = this._dynamicNumber % 3
      this._dynamicEntities.forEach((entity, index) => {
        entity.show = false
        if (mod === index % 3) {
          entity.show = true
        }
      })
      this._dynamicNumber++
      if (this._dynamicNumber === 3) {
        this._dynamicNumber = 0
      }
    }
    this._tickNumber++
  }
  getPolygon () {
    const currentTime = this._viewer.clock.currentTime
    let timeLast = new Cesium.JulianDate()
    Cesium.JulianDate.addSeconds(currentTime, -12, timeLast)
    const positionLast = getPosition(timeLast, this._positions)
    let timeNext = new Cesium.JulianDate()
    Cesium.JulianDate.addSeconds(currentTime, 12, timeNext)
    const positionNext = getPosition(timeNext, this._positions)
    const polygon = getPolygon(positionLast, positionNext, 85000)
    return polygon
  }
  destroy () {
    this._entities.forEach(entity => {
      this._viewer.entities.remove(entity)
    })
    this._dynamicEntities.forEach(entity => {
      this._viewer.entities.remove(entity)
    })
    this._dynamicEntities = []
    this._entities = []
    this._viewer.clock.onTick.removeEventListener(this.tick, this)
  }
}
export default PyramidEntityF
