/*
 * @Author: chen wei
 * @Date: 2023-03-02 11:36:31
 * @LastEditTime: 2023-03-02 16:07:32
 * @Description: 
 */
import React, { Fragment } from 'react'
import gif_bg from "../../common/images/2.gif"
import github_log from "../../common/images/github.png"
import abstract_log from "../../common/images/paint-can.png"
import earth_log from "../../common/images/earth.png"
import works_log from "../../common/images/works.png"
import { useNavigate } from 'react-router-dom';
import "./myself.less"


function Myself(){
    const navigate = useNavigate();
    return (
        <Fragment>
            <div className={"back-color"}>
                <img src={ gif_bg } alt="" className={'back-img'}></img>
                <div className={'github-log'}>
                    <img src={ github_log } alt="" ></img>
                    <img src={ works_log } alt="" onClick={() => navigate('/Editor')}></img>
                    <img src={ earth_log } alt="" onClick={() => navigate('/effect')}></img>
                    <img src={ abstract_log } alt="" onClick={() => navigate('/intro')}></img>
                </div>
            </div>
        </Fragment>
    )
}
export default Myself