import BK from "../../common/images/bk.jpg"
import {satelliteInfo} from "../../common/js/satellite"
import EmissionMaterial from './EmissionMaterial'
import {getDate, getPosition, getOrientation} from './satelliteUtil'
import ImageParticleF from './ImageParticle'
import PyramidEntityF from './PyramidEntity'


let viewer,satelliteEntity={},isRealtime,earthRotateSpeed,scanTrackPositions,scanTrackEntity,ImageParticle,PyramidEntity

export function initViewer () {
    viewer = new Cesium.Viewer('satellite', {
      infoBox: true,
      orderIndependentTranslucency: false,
      contextOptions: {
        webgl: { alpha: true
        },
        maxDrawingBufferWidth: 3840,
        maxDrawingBufferHeight: 2160}
      // animation: true
      // timeline: true
    })
    // viewer.scene.debugShowFramesPerSecond = true
    viewer.scene.sun.show = false
    viewer.scene.moon.show = false
    viewer.scene.globe.depthTestAgainstTerrain = false
    viewer.imageryLayers.removeAll()
    const imageryLayer = viewer.imageryLayers.addImageryProvider(new Cesium.SingleTileImageryProvider({
      url: BK
    }))
    imageryLayer.brightness = 0.8
    viewer.scene.globe.enableLighting = true
    // 开启抗锯齿
    viewer.scene.postProcessStages.fxaa.enabled = true
    // viewer.scene.bloomEffect.show = true
    // viewer.scene.bloomEffect.threshold = 0.01
    // viewer.scene.bloomEffect.bloomIntensity = 0.2
    // viewer.scene.skyBox.show = false
    viewer.scene.backgroundColor = new Cesium.Color(0.0, 0.0, 0.0, 0.0)
}
/**
     * 添加卫星轨道数据
     */
export function addData () {
    const satellitePositions = {}
    const satellitePromises = [] // 卫星数据promise数组
    satelliteInfo.forEach(satellite => {
      // 加载卫星轨道数据
      const satellitePromise = Cesium.CzmlDataSource.load(satellite.path)
      satellitePromises.push(satellitePromise)
    })
    const dataSourcePromises = [] // 卫星轨道数据源promise数组
    Cesium.when.all(satellitePromises, (dataSources) => {
      dataSources.forEach(datasource => {
        // 轨迹轨道数据源添加至场景
        const dataSourcePromise = viewer.dataSources.add(datasource)
        dataSourcePromises.push(dataSourcePromise)
      })
      Cesium.when.all(dataSourcePromises, (satelliteSources) => {
        let gd = getDate('2021-11-05 11:00:00')
        // 设置场景时间为当前时间，时间速度默认为1s
        // viewer.clock.currentTime = gd.start
        // viewer.clock.multiplier = 120
        // viewer.clock.onTick.addEventListener(this.tick)
        satelliteSources.forEach((satelliteSource, index) => {
          const entity = satelliteSource.entities.values[0]
          satelliteEntity[satelliteInfo[index].id] = entity
          satellitePositions[satelliteInfo[index].id] = entity.position
          entity.position.setInterpolationOptions({
            interpolationDegree: 10
          })
          // 隐藏卫星billboard图标
          entity.billboard.show = false
          entity.point = new Cesium.PointGraphics({
            color: Cesium.Color.WHITE.withAlpha(0),
            pixelSize: 4,
            outlineColor: Cesium.Color.WHITE.withAlpha(0.8),
            outlineWidth: 1
          })
          entity.label.font = '16px sans-serif'
          entity.label.fillColor = Cesium.Color.WHITE
          // 修改卫星轨迹材质
          entity.path.material = new EmissionMaterial(Cesium.Color.fromCssColorString(satelliteInfo[index].color).withAlpha(0.5))
          // 修改卫星轨迹拖尾长度，为一周的一半
          entity.path.trailTime = 4000
          // 卫星提前预测轨迹长度设置成0
          entity.path.leadTime = 0
          // 添加模型，位置跟此时卫星轨迹点位置一致
          const entityGltf = viewer.entities.add({
            id: satelliteInfo[index].id,
            position: satellitePositions[satelliteInfo[index].id],
            orientation: new Cesium.CallbackProperty(() => {
              return getOrientation(viewer, satellitePositions[satelliteInfo[index].id])
            }, false),
            model: {
              uri: satelliteInfo[index].modelUrl,
              scale: 1,
              runAnimations: true
            }
          })
          // 设置模型查看视图
          entityGltf.viewFrom = new Cesium.Cartesian3(-250000, -115000, 1000000)
          // 去除环境光对模型的影响
          // entityGltf.model.imageBasedLightingFactor = new Cesium.Cartesian2(0, 0)
          // 关闭模型阴影
          entityGltf.model.shadows = 1
        })
      })
    })
}
  /**
   * 场景时钟每帧渲染的参数
   */
export function tick () {
    const camera = viewer.camera
    // 旋转相机，达到地球自转效果
    camera.rotate(Cesium.Cartesian3.UNIT_Z, earthRotateSpeed)
}

export function showPar(){
getMessage(
    {
    data:JSON.stringify(
        {
        type:0,
        value:'TERRA'
        }
    )
    }
)
}
/**
 * 接收控制端传输的信息
 * @param msg
 */
export function getMessage (msg) {
if (msg.data === 'ping') {
    return
}
const data = JSON.parse(msg.data)
switch (data.type) {
    case 0:
        clearALl()
        satelliteProcess(data.value)
    break
    case 1:
        clearALl()
    break
    case 2:
        isRealtime = data.value
        viewer.clock.multiplier = 120
        if (viewer.trackedEntity) {
            viewer.clock.multiplier = 30
        }
        earthRotateSpeed = 0.0005
        if (data.value) {
            earthRotateSpeed = 0
            viewer.clock.multiplier = 1
        }
    break
}
}
/**
 * 添加卫星在地面扫描的轨迹线
 * @param positions
 */
export function addScanTrackEntity (positions) {
scanTrackPositions = []
scanTrackEntity = viewer.entities.add({
    corridor: {
    positions: new Cesium.CallbackProperty(() => {
        let timeLast = new Cesium.JulianDate()
        Cesium.JulianDate.addSeconds(viewer.clock.currentTime, -2.5, timeLast)
        const positionP = getPosition(timeLast, positions)
        scanTrackPositions.push(Cesium.Cartesian3.fromDegrees(positionP.longitude, positionP.latitude, 0))
        return scanTrackPositions
    }, false),
    width: getPosition(viewer.clock.currentTime, positions).height / 16,
    fill: false,
    cornerType: 1,
    outline: true,
    height: 1.0,
    outlineColor: Cesium.Color.YELLOW,
    outlineWidth: 5
    }
})
}
/**
 * 扫描的四棱锥示意线
 * @param positions
 */
export function addPyramidEntity (positions) {
    PyramidEntity = new PyramidEntityF(viewer, positions)
}   
export function satelliteProcess (id) {
if (id) {
    // 获取对应卫星的gltf模型，并锁定视角
    viewer.clock.multiplier = 0
    const entity = viewer.entities.getById(id)
    entity.model.scale = 25000
    Object.keys(satelliteEntity).forEach(key => {
        satelliteEntity[key].show = false
    })
    let gd = getDate('2021-11-06 09:37:00')
    // 设置场景时间为当前时间，时间速度默认为1s
    viewer.flyTo(entity)
    viewer.clock.currentTime = gd.start
    setTimeout(() => {
        viewer.scene.camera.cancelFlight()
        viewer.trackedEntity = entity
        ImageParticle = new ImageParticleF(viewer, entity.position)
        ImageParticle.setTimeRange(0.747 * 30)
        setTimeout(() => {
            viewer.clock.multiplier = 30
            earthRotateSpeed = 0.0005
            if (isRealtime) {
                earthRotateSpeed = 0
                viewer.clock.multiplier = 1
            }
            addScanTrackEntity(entity.position)
            // addPyramidEntity(entity.position)
        }, 2000)
    }, 1800)
} else {
    clearALl()
    viewer.camera.flyHome(1)
}
}
export function clearALl () {
    Object.keys(satelliteEntity).forEach(key => {
        satelliteEntity[key].show = true
    })
    if (viewer.trackedEntity) {
        viewer.trackedEntity.model.scale = 1
    }
    viewer.trackedEntity = undefined
    if (scanTrackEntity) {
        viewer.entities.remove(scanTrackEntity)
        scanTrackEntity = null
    }
    if (PyramidEntity) {
        PyramidEntity.destroy()
        PyramidEntity = null
    }
    if (ImageParticle) {
        ImageParticle.destroy()
        ImageParticle = null
    }
}