const Cesium = window.Cesium
/**
 * 获取JulianDate时间，需要+8小时
 * @returns {{start: *}}
 */
function getDate (time) {
  let d = new Date()
  if (time) {
    d = new Date(time)
  }
  let t = [d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()]
  let start = Cesium.JulianDate.fromDate(new Date(t[0], t[1], t[2], t[3], t[4], t[5]))
  return {start}
}
/**
 * 获取卫星当前时刻的经纬度位置
 * @param time
 * @param positions
 * @returns {{latitude: Number, longitude: Number, height: *}}
 */
function getPosition (time, positions) {
  let satelliteP = positions.getValue(time)
  const cartographic = Cesium.Cartographic.fromCartesian(satelliteP)
  const longitude = Cesium.Math.toDegrees(cartographic.longitude)
  const latitude = Cesium.Math.toDegrees(cartographic.latitude)
  const height = cartographic.height
  return {
    longitude,
    latitude,
    height
  }
}

/**
 * 计算两点连线指定宽度的平头缓冲区
 * @param po1
 * @param po2
 * @param width
 * @returns {FlatArray<Position[] | Position[][], 1>[]}
 */
function getPolygon (po1, po2, width) {
  const line = turf.lineString([
    [po1.longitude, po1.latitude],
    [po2.longitude, po2.latitude]
  ])
  const buffered = turf.buffer(line, width / 1000, {units: 'kilometers', steps: 1})
  // 处理成平角缓冲区
  let hieraP = buffered.geometry.coordinates[0]
  // 去掉尾点
  hieraP.splice(4, 1)
  // 去掉头点
  hieraP.splice(1, 1)
  return hieraP.flat()
}

/**
 * 添加全局光照，共六个方向的平行光
 * @param viewer
 */
function addLight (viewer) {
  const DirectionalLight = new Cesium.DirectionalLight(new Cesium.Cartesian3(0, 1, 0), {
    intensity: 0.2
  })
  viewer.scene.addLightSource(DirectionalLight)
  const DirectionalLight1 = new Cesium.DirectionalLight(new Cesium.Cartesian3(0, -1, 0), {
    intensity: 0.2
  })
  viewer.scene.addLightSource(DirectionalLight1)
  const DirectionalLight2 = new Cesium.DirectionalLight(new Cesium.Cartesian3(1, 0, 0), {
    intensity: 0.2
  })
  viewer.scene.addLightSource(DirectionalLight2)
  const DirectionalLight3 = new Cesium.DirectionalLight(new Cesium.Cartesian3(-1, 0, 0), {
    intensity: 0.2
  })
  viewer.scene.addLightSource(DirectionalLight3)
  const DirectionalLight4 = new Cesium.DirectionalLight(new Cesium.Cartesian3(0, 0, 1), {
    intensity: 0.1
  })
  viewer.scene.addLightSource(DirectionalLight4)
  const DirectionalLight5 = new Cesium.DirectionalLight(new Cesium.Cartesian3(0, 0, -1), {
    intensity: 0.1
  })
  viewer.scene.addLightSource(DirectionalLight5)
}

/**
 * 动态获取卫星空间姿态
 * @param viewer
 * @param positions
 * @returns {*}
 */
function getOrientation (viewer, positions) {
  const currentTime = viewer.clock.currentTime
  const position1 = positions.getValue(currentTime)
  let timeNext = new Cesium.JulianDate()
  Cesium.JulianDate.addSeconds(currentTime, 0.5, timeNext)
  const position2 = positions.getValue(timeNext)
  // 向量AB
  const vector2 = Cesium.Cartesian3.subtract(position2, position1, new Cesium.Cartesian3())
  // 归一化
  const normal = Cesium.Cartesian3.normalize(vector2, new Cesium.Cartesian3())
  // 旋转矩阵 rotationMatrixFromPositionVelocity源码中有，并未出现在cesiumAPI中
  const rotationMatrix3 = Cesium.Transforms.rotationMatrixFromPositionVelocity(position1, normal, Cesium.Ellipsoid.WGS84)
  const orientation = Cesium.Quaternion.fromRotationMatrix(rotationMatrix3)
  return orientation
}
function addLandsatImage (viewer) {
  const imageInfo = require('./thumbnail_bounds.json')
  imageInfo.forEach(info => {
    const path = require('../../common/images/2021-10-08/' + info.name + '.png')
    viewer.entities.add({
      name: info.name,
      rectangle: {
        coordinates: Cesium.Rectangle.fromDegrees(
          info.bounds.left,
          info.bounds.bottom,
          info.bounds.right,
          info.bounds.top
        ),
        material: new Cesium.ImageMaterialProperty({
          image: path
        })
      }
    })
  })
}
export {getDate, getPosition, getPolygon, addLight, getOrientation, addLandsatImage}
