import React,{ Fragment, useEffect } from "react"
import "./sattlite.less"
import {initViewer,showPar,addData} from "./world"

function Sattelit(){
    useEffect(() => {
        console.log('DidMount 和 DidUpdate')
        initViewer()
        addData()
    })
    return (
        <Fragment>
            <div className={"luy-out"}>
                <div id="satellite"></div>
                <div className={"tool"}>
                    <div className={"start"} onClick={() => showPar()}>开始</div>
                </div>
            </div>
        </Fragment>
    )
}
export default Sattelit