/*
 * @Author: chen wei
 * @Date: 2023-03-03 16:45:29
 * @LastEditTime: 2023-03-03 17:30:33
 * @Description: 
 */
import React,{ Fragment, useEffect } from "react"
import "./introduction.less"
import "./style.css"
import {start,destoryOn} from "./3dWorld/start"

function Intro(){
    useEffect(() => {
        start()
        return destory
    })
    const destory = () =>{
        destoryOn()
    }
    return (
        <Fragment>
            <div className={"luy-out"}>
                <div className={"mask-load"} id="loading">
                    <div className={"trinity-rings-spinner preload"}>
                        <div className={"circle preload"}></div>
                        <div className={"circle preload"}></div>
                        <div className={"circle preload"}></div>
                    </div>
                    <div className={"loading-text-div preload"}>Loading<span className={"loader__dot"}>.</span><span className={"loader__dot"}>.</span><span className={"loader__dot"}>.</span></div>
                </div>
                <div id="intro"></div>
            </div>
        </Fragment>
    )
}
export default Intro