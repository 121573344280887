import {getPolygon, getPosition} from './satelliteUtil'
import {tileImage} from '../../common/js/satellite'

const Cesium = window.Cesium

class ImageParticleF {
  constructor (viewer, positions) {
    this._viewer = viewer
    this._positions = positions
    this._imagePrimitive = new Cesium.PrimitiveCollection()
    this._imageNumber = 0
    this.timeRange = 1
    viewer.scene.primitives.add(this._imagePrimitive)
    this.nowPrimitive = this.createPrimitive()
    setTimeout(() => {
      this._viewer.clock.onTick.addEventListener(this.tick, this)
    }, 2000)
  }
  setTimeRange (value) {
    this.timeRange = value
  }
  destroy () {
    this._viewer.scene.primitives.remove(this._imagePrimitive)
    this._imagePrimitive = undefined
    this._viewer.clock.onTick.removeEventListener(this.tick, this)
  }
  tick () {
    if (this.nowPrimitive) {
      const viewer = this._viewer
      const currentTime = viewer.clock.currentTime
      const range = Cesium.JulianDate.secondsDifference(currentTime, this.nowPrimitive.currentTime)
      if (range <= this.timeRange) {
        const color = new Cesium.Color(1, 1, 1, range / this.timeRange)
        this.nowPrimitive.appearance.material.materials.color.uniforms.color = color
      } else {
        const color = new Cesium.Color(1, 1, 1, 1)
        this.nowPrimitive.appearance.material.materials.color.uniforms.color = color
        this._imageNumber++
        this.nowPrimitive = this.createPrimitive()
      }
    }
  }
  createPrimitive () {
    const positions = this._positions
    const viewer = this._viewer
    const currentTime = viewer.clock.currentTime
    let timeLast = new Cesium.JulianDate()
    Cesium.JulianDate.addSeconds(currentTime, -12, timeLast)
    const positionLast = getPosition(timeLast, positions)
    let timeNext = new Cesium.JulianDate()
    Cesium.JulianDate.addSeconds(currentTime, 12, timeNext)
    const positionNext = getPosition(timeNext, positions)
    const polygon = getPolygon(positionLast, positionNext, 85000)
    const primitive = this._imagePrimitive.add(
      new Cesium.Primitive({
        geometryInstances: new Cesium.GeometryInstance({
          geometry: new Cesium.PolygonGeometry({
            polygonHierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray(polygon.flat())),
            vertexFormat: Cesium.EllipsoidSurfaceAppearance.VERTEX_FORMAT
          })
        }),
        appearance: new Cesium.EllipsoidSurfaceAppearance({
          aboveGround: true,
          material: new Cesium.Material({
            fabric: {
              type: 'transImage',
              materials: {
                diffuseMaterial: {
                  type: 'Image',
                  uniforms: {
                    image: tileImage[this._imageNumber % 12]
                  }
                },
                color: {
                  type: 'Color',
                  uniforms: {
                    color: new Cesium.Color(1, 1, 1, 0)
                  }
                }
              },
              components: {
                diffuse: 'diffuseMaterial.diffuse',
                alpha: 'diffuseMaterial.alpha * color.alpha'
              }
            }
          })
        })
      })
    )
    primitive.currentTime = currentTime
    return primitive
  }
}

export default ImageParticleF
