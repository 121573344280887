import * as THREE from 'three';
import earth from "./jsm/earth.jpg"
import lensflare0 from "./jsm/lensflare0.png"
import spark from "./jsm/spark.png"
import boxTexture from './jsm/githubLogo.png'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
    launchHover,
    launchClickPosition
} from "./js/ray"


export let cursorHoverObjects = []
export let scene, camera, renderer;
let scriptAmmo;
export const destoryOn = () => {
    document.removeEventListener('click',launchClickPosition);
    document.removeEventListener('mousemove',launchHover);
    document.head.removeChild(scriptAmmo);
}

export function start(){
    scriptAmmo = document.createElement("script");
    scriptAmmo.type = "text/javascript";
    scriptAmmo.async = true;
    scriptAmmo.src = "./js/ammo.js";
    scriptAmmo.title = "scriptAmmo";
    document.head.appendChild(scriptAmmo);
    scriptAmmo.onload = () => {
        Ammo().then(start)
    }
    
    let galaxyMaterial = null;
    let galaxyPoints = null;
    let galaxyClock = null;

    let physicsWorld, clock, rigidBodies = [], tmpTrans = null,particleSystemObject,lensFlareObject
    let ballObject = null, moveDirection = { left: 0, right: 0, forward: 0, back: 0 }
    let kObject = null, kMoveDirection = { left: 0, right: 0, forward: 0, back: 0 }, tmpPos = new THREE.Vector3(), tmpQuat = new THREE.Quaternion();
    let ammoTmpPos = null, ammoTmpQuat = null;
    let mouseCoords = new THREE.Vector2(), raycaster = new THREE.Raycaster();
    let particleGroup,particleAttributes ;
    
    let manager = new THREE.LoadingManager();

    window.scene = scene

    const STATE = { DISABLE_DEACTIVATION : 4 }

    const FLAGS = { CF_KINEMATIC_OBJECT: 2 }

    //Ammojs Initialization
    
    const removeLoading = () => {
        const dom = document.getElementById("loading")
        dom.style.visibility = 'hidden'; // or
        dom.style.display = 'none';
    }
    function start (){
        removeLoading()
        tmpTrans = new Ammo.btTransform();
        ammoTmpPos = new Ammo.btVector3();
        ammoTmpQuat = new Ammo.btQuaternion();

        setupPhysicsWorld();
        setupGraphics();

        //射线拾取监听
        setTimeout(() => {
            document.addEventListener('click', launchClickPosition);
            document.addEventListener('mousemove', launchHover);
        }, 1000);
        
        createLensFlare(50, -50, -800, 200, 200, lensflare0);
        createBlock();
        createBall();
        // createBall2()
        
        //星云
        generateGalaxy()
        //星空
        addParticles()
        glowingParticles()

        //绿色盒子
        createKinematicBox();

        //监听控制球移动
        setupEventHandlers();
        renderFrame();

    }

    function glowingParticles() {
        var particleTextureLoader = new THREE.TextureLoader(manager);
        var particleTexture = particleTextureLoader.load(spark);
      
        particleGroup = new THREE.Object3D();
        particleGroup.position.x = -1;
        particleGroup.position.y = 7;
        particleGroup.position.z = 45;
        particleAttributes = { startSize: [], startPosition: [], randomness: [] };
      
        var totalParticles = 50;
        var radiusRange = 4;
        for (var i = 0; i < totalParticles; i++) {
          var spriteMaterial = new THREE.SpriteMaterial({
            map: particleTexture,
            color: 0xffffff,
          });
      
          var sprite = new THREE.Sprite(spriteMaterial);
          sprite.scale.set(0.5, 0.5, 1.0); // imageWidth, imageHeight
          sprite.position.set(
            Math.random() - 0.5,
            Math.random() - 0.5,
            Math.random() - 0.5
          );
      
          sprite.position.setLength(radiusRange * (Math.random() * 0.1 + 0.9));
      
          sprite.material.color.setHSL(Math.random(), 0.9, 0.7);
      
          sprite.material.blending = THREE.AdditiveBlending; // "glowing" particles
          sprite.renderOrder = 1;
          particleGroup.add(sprite);
          // add variable qualities to arrays, if they need to be accessed later
          particleAttributes.startPosition.push(sprite.position.clone());
          particleAttributes.randomness.push(Math.random());
        }
      
        scene.add(particleGroup);
      }

    function createLensFlare(x, y, z, xScale, zScale, boxTexture) {
        const boxScale = { x: xScale, y: 0.1, z: zScale };
        let quat = { x: 0, y: 0, z: 0, w: 1 };
        let mass = 0; //mass of zero = infinite mass
      
        var geometry = new THREE.PlaneBufferGeometry(xScale, zScale);
      
        const loader = new THREE.TextureLoader();
        const texture = loader.load(boxTexture);
        texture.magFilter = THREE.LinearFilter;
        texture.minFilter = THREE.LinearFilter;
        texture.encoding = THREE.sRGBEncoding;
        const loadedTexture = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true,
          opacity: 0.9,
        });
        loadedTexture.depthWrite = true;
        loadedTexture.depthTest = true;
      
        lensFlareObject = new THREE.Mesh(geometry, loadedTexture);
        lensFlareObject.position.set(x, y, z);
        lensFlareObject.renderOrder = 1;
      
        lensFlareObject.receiveShadow = true;
        // scene.add(lensFlareObject);
    }

    function moveParticles() {
        particleSystemObject.rotation.z += 0.0003;
        lensFlareObject.rotation.z += 0.0002;
        if (lensFlareObject.position.x < 750) {
          lensFlareObject.position.x += 0.025;
          lensFlareObject.position.y -= 0.001;
        } else {
          lensFlareObject.position.x = -750;
          lensFlareObject.position.y = -50;
        }
      
        //move stemkoski particles
        var time = 7 * clock.getElapsedTime();
      
        for (var c = 0; c < particleGroup.children.length; c++) {
          var sprite = particleGroup.children[c];
      
          // pulse away/towards center
          // individual rates of movement
          var a = particleAttributes.randomness[c] + 0.75;
          var pulseFactor = Math.sin(a * time) * 0.1 + 0.9;
          sprite.position.x = particleAttributes.startPosition[c].x * pulseFactor;
          sprite.position.y =
            particleAttributes.startPosition[c].y * pulseFactor * 1.5;
          sprite.position.z = particleAttributes.startPosition[c].z * pulseFactor;
        }
      
        // rotate the entire group
        //particleGroup.rotation.x = time * 0.5;
        particleGroup.rotation.y = time * 0.75;
        // particleGroup.rotation.z = time * 1.0;
      }

    function addParticles() {
        var geometry = new THREE.Geometry();
      
        for (let i = 0; i < 4000; i++) {
          var vertex = new THREE.Vector3();
          vertex.x = getRandomArbitrary(-1100, 1100);
          vertex.y = getRandomArbitrary(-1100, 1100);
          vertex.z = getRandomArbitrary(-1100, -500);
          geometry.vertices.push(vertex);
        }
      
        var material = new THREE.PointsMaterial({ size: 3 });
        particleSystemObject = new THREE.Points(geometry, material);
      
        scene.add(particleSystemObject);
    }
    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    function generateGalaxy () {

        const parameters = {};
        parameters.count = 50000;
        parameters.size = 0.005;
        parameters.radius = 100;
        parameters.branches = 3;
        parameters.spin = 1;
      
        parameters.randomnessPower = 3;
        parameters.insideColor = '#ff6030';
        parameters.outsideColor = '#1b3984';
        parameters.randomness = 0.2;
      
        let geometry = null;
        galaxyMaterial = null;
        galaxyPoints = null;
        if (galaxyPoints !== null) {
          geometry.dispose();
          galaxyMaterial.dispose();
          scene.remove(galaxyPoints);
        }
      
        /**
         * Geometry
         */
        geometry = new THREE.BufferGeometry();
      
        const positions = new Float32Array(parameters.count * 3);
        const randomness = new Float32Array(parameters.count * 3);
      
        const colors = new Float32Array(parameters.count * 3);
        const scales = new Float32Array(parameters.count * 1);
      
        const insideColor = new THREE.Color(parameters.insideColor);
        const outsideColor = new THREE.Color(parameters.outsideColor);
      
        for (let i = 0; i < parameters.count; i++) {
          const i3 = i * 3;
      
          // Position
          const radius = Math.random() * parameters.radius;
      
          const branchAngle =
            ((i % parameters.branches) / parameters.branches) * Math.PI * 2;
      
          const randomX =
            Math.pow(Math.random(), parameters.randomnessPower) *
            (Math.random() < 0.5 ? 1 : -1) *
            parameters.randomness *
            radius;
          const randomY =
            Math.pow(Math.random(), parameters.randomnessPower) *
            (Math.random() < 0.5 ? 1 : -1) *
            parameters.randomness *
            radius;
          const randomZ =
            Math.pow(Math.random(), parameters.randomnessPower) *
              (Math.random() < 0.5 ? 1 : -1) *
              parameters.randomness *
              radius -
            50;
      
          positions[i3] = Math.cos(branchAngle) * radius;
          positions[i3 + 1] = 0;
          positions[i3 + 2] = Math.sin(branchAngle) * radius;
      
          randomness[i3] = randomX;
          randomness[i3 + 1] = randomY;
          randomness[i3 + 2] = randomZ;
      
          // Color
          const mixedColor = insideColor.clone();
          mixedColor.lerp(outsideColor, radius / parameters.radius);
      
          colors[i3] = mixedColor.r;
          colors[i3 + 1] = mixedColor.g;
          colors[i3 + 2] = mixedColor.b;
      
          // Scale
          scales[i] = Math.random();
        }
      
        geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
        geometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1));
        geometry.setAttribute(
          'aRandomness',
          new THREE.BufferAttribute(randomness, 3)
        );
      
        /**
         * Material
         */
        galaxyMaterial = new THREE.ShaderMaterial({
          size: parameters.size,
          sizeAttenuation: true,
          depthWrite: false,
          blending: THREE.AdditiveBlending,
          vertexColors: true,
          vertexShader: window.Ver,
          fragmentShader: window.Fra,
          uniforms: {
            uTime: { value: 0 },
            uSize: { value: 30 * renderer.getPixelRatio() },
          },
        });
        var material = new THREE.PointsMaterial({
            color: 0x0000ff, //颜色
            size: 30, //点渲染尺寸
        });
      
        /**
         * Points
         */
        galaxyPoints = new THREE.Points(geometry, galaxyMaterial);
        galaxyPoints.position.y = -50;
        scene.add(galaxyPoints);
    };


    function setupPhysicsWorld(){

        let collisionConfiguration  = new Ammo.btDefaultCollisionConfiguration(),
            dispatcher              = new Ammo.btCollisionDispatcher(collisionConfiguration),
            overlappingPairCache    = new Ammo.btDbvtBroadphase(),
            solver                  = new Ammo.btSequentialImpulseConstraintSolver();

        physicsWorld           = new Ammo.btDiscreteDynamicsWorld(dispatcher, overlappingPairCache, solver, collisionConfiguration);
        physicsWorld.setGravity(new Ammo.btVector3(0, -10, 0));

    }



    function setupGraphics() {
        clock = new THREE.Clock();
        galaxyClock = new THREE.Clock();
      
        // init new Three.js scene
        scene = new THREE.Scene();
        scene.background = new THREE.Color(0x000000);
      
        // camera
        camera = new THREE.PerspectiveCamera(
          45,
          window.innerWidth / window.innerHeight,
          1,
          5000
        );
        camera.position.set(0, 30, 120);
        //camera.lookAt(scene.position);

        
      
        //Add hemisphere light
        let hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.1);
        hemiLight.color.setHSL(0.6, 0.6, 0.6);
        hemiLight.groundColor.setHSL(0.1, 1, 0.4);
        hemiLight.position.set(0, 50, 0);
        scene.add(hemiLight);
      
        //Add directional light
        let dirLight = new THREE.DirectionalLight(0xffffff, 0.7);
        dirLight.color.setHSL(0.1, 1, 0.95);
        dirLight.position.set(-10, 100, 50);
        dirLight.position.multiplyScalar(100);
        scene.add(dirLight);
      
        dirLight.castShadow = true;
      
        dirLight.shadow.mapSize.width = 4096;
        dirLight.shadow.mapSize.height = 4096;
      
        let d = 200;
      
        dirLight.shadow.camera.left = -d;
        dirLight.shadow.camera.right = d;
        dirLight.shadow.camera.top = d;
        dirLight.shadow.camera.bottom = -d;
      
        dirLight.shadow.camera.far = 15000;
      
        //Setup the renderer
        renderer = new THREE.WebGLRenderer({ antialias: true });
        //renderer.setClearColor(0xbfd1e5);
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        //renderer.shadowMap.type = THREE.BasicShadowMap;
        const contants = document.getElementById("intro")
        contants.appendChild( renderer.domElement );
      
        renderer.gammaInput = true;
        renderer.gammaOutput = true;
      
        renderer.shadowMap.enabled = true;

        
        const controls = new OrbitControls( camera, renderer.domElement );
        controls.update();
        // controls.enablePan = false;
        // controls.enableDamping = true;

        
      }


    function renderFrame(){

        let deltaTime = clock.getDelta();
        
        const elapsedTime = galaxyClock.getElapsedTime() + 150;
        
        moveBall();
        moveKinematic();

        updatePhysics( deltaTime );

        renderer.render( scene, camera );
        
        //移动星云
        galaxyMaterial.uniforms.uTime.value = elapsedTime * 5;

        //移动星空
        moveParticles()

        requestAnimationFrame( renderFrame );

    }

    function setupEventHandlers(){
        //控制小球移动
        window.addEventListener( 'keydown', handleKeyDown, false);
        window.addEventListener( 'keyup', handleKeyUp, false);
        //发射球
        // window.addEventListener( 'mousedown', onMouseDown, false );

    }


    function handleKeyDown(event){

        let keyCode = event.keyCode;

        switch(keyCode){

            case 87: //W: FORWARD
                moveDirection.forward = 1
                break;
                
            case 83: //S: BACK
                moveDirection.back = 1
                break;
                
            case 65: //A: LEFT
                moveDirection.left = 1
                break;
                
            case 68: //D: RIGHT
                moveDirection.right = 1
                break;

            case 38: //↑: FORWARD
                kMoveDirection.forward = 1
                break;
                
            case 40: //↓: BACK
                kMoveDirection.back = 1
                break;
                
            case 37: //←: LEFT
                kMoveDirection.left = 1
                break;
                
            case 39: //→: RIGHT
                kMoveDirection.right = 1
                break;
                
        }
    }
    
    
    function handleKeyUp(event){
        let keyCode = event.keyCode;

        switch(keyCode){
            case 87: //FORWARD
                moveDirection.forward = 0
                break;
                
            case 83: //BACK
                moveDirection.back = 0
                break;
                
            case 65: //LEFT
                moveDirection.left = 0
                break;
                
            case 68: //RIGHT
                moveDirection.right = 0
                break;

            case 38: //↑: FORWARD
                kMoveDirection.forward = 0
                break;
                
            case 40: //↓: BACK
                kMoveDirection.back = 0
                break;
                
            case 37: //←: LEFT
                kMoveDirection.left = 0
                break;
                
            case 39: //→: RIGHT
                kMoveDirection.right = 0
                break;
        }

    }

                
    function onMouseDown ( event ) {

        mouseCoords.set(
            ( event.clientX / window.innerWidth ) * 2 - 1,
            - ( event.clientY / window.innerHeight ) * 2 + 1
        );


        raycaster.setFromCamera( mouseCoords, camera );

        // Creates a ball and throws it

        tmpPos.copy( raycaster.ray.direction );
        tmpPos.add( raycaster.ray.origin );


        let pos = {x: tmpPos.x, y: tmpPos.y, z: tmpPos.z};
        let radius = 1;
        let quat = {x: 0, y: 0, z: 0, w: 1};
        let mass = 1;

        //threeJS Section
        let ball = new THREE.Mesh(new THREE.SphereBufferGeometry(radius), new THREE.MeshPhongMaterial({color: 0x6b246e}));

        ball.position.set(pos.x, pos.y, pos.z);
        
        ball.castShadow = true;
        ball.receiveShadow = true;

        scene.add(ball);


        //Ammojs Section
        let transform = new Ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin( new Ammo.btVector3( pos.x, pos.y, pos.z ) );
        transform.setRotation( new Ammo.btQuaternion( quat.x, quat.y, quat.z, quat.w ) );
        let motionState = new Ammo.btDefaultMotionState( transform );

        let colShape = new Ammo.btSphereShape( radius );
        colShape.setMargin( 0.05 );

        let localInertia = new Ammo.btVector3( 0, 0, 0 );
        colShape.calculateLocalInertia( mass, localInertia );

        let rbInfo = new Ammo.btRigidBodyConstructionInfo( mass, motionState, colShape, localInertia );
        let body = new Ammo.btRigidBody( rbInfo );

        physicsWorld.addRigidBody( body );

        tmpPos.copy( raycaster.ray.direction );
        tmpPos.multiplyScalar( 100 );

        body.setLinearVelocity( new Ammo.btVector3( tmpPos.x, tmpPos.y, tmpPos.z ) );
        
        ball.userData.physicsBody = body;
        rigidBodies.push(ball);

    }



    function createBlock2(){
        
        let pos = {x: 0, y: 0, z: 0};
        let scale = {x: 300, y: 2, z: 300};
        let quat = {x: 0, y: 0, z: 0, w: 1};
        let mass = 0;

        //threeJS Section
        let blockPlane = new THREE.Mesh(new THREE.BoxBufferGeometry(), new THREE.MeshPhongMaterial({color: 'red'}));

        blockPlane.position.set(pos.x, pos.y, pos.z);
        blockPlane.scale.set(scale.x, scale.y, scale.z);

        blockPlane.castShadow = true;
        blockPlane.receiveShadow = true;

        scene.add(blockPlane);


        //Ammojs Section
        let transform = new Ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin( new Ammo.btVector3( pos.x, pos.y, pos.z ) );
        transform.setRotation( new Ammo.btQuaternion( quat.x, quat.y, quat.z, quat.w ) );
        let motionState = new Ammo.btDefaultMotionState( transform );

        let colShape = new Ammo.btBoxShape( new Ammo.btVector3( scale.x * 0.5, scale.y * 0.5, scale.z * 0.5 ) );
        colShape.setMargin( 0.05 );

        let localInertia = new Ammo.btVector3( 0, 0, 0 );
        colShape.calculateLocalInertia( mass, localInertia );

        let rbInfo = new Ammo.btRigidBodyConstructionInfo( mass, motionState, colShape, localInertia );
        let body = new Ammo.btRigidBody( rbInfo );

        body.setFriction(4);
        body.setRollingFriction(10);

        physicsWorld.addRigidBody( body );
    }

    function createBlock() {
        
        // block properties
        let pos = { x: 0, y: -0.25, z: 0 };
        let scale = { x: 175, y: 0.5, z: 175 };
        let quat = { x: 0, y: 0, z: 0, w: 1 };
        let mass = 0; //mass of zero = infinite mass

        //create grid overlay on plane
        var grid = new THREE.GridHelper(175, 20, 0xffffff, 0xffffff);
        grid.material.opacity = 0.5;
        grid.material.transparent = true;
        grid.position.y = 0.005;
        scene.add(grid);

        //Create Threejs Plane
        let blockPlane = new THREE.Mesh(
        new THREE.BoxBufferGeometry(),
        new THREE.MeshPhongMaterial({
            color: 0xffffff,
            transparent: true,
            opacity: 0.25,
        })
        );
        blockPlane.position.set(pos.x, pos.y, pos.z);
        blockPlane.scale.set(scale.x, scale.y, scale.z);
        blockPlane.receiveShadow = true;
        scene.add(blockPlane);

        //Ammo.js Physics
        let transform = new Ammo.btTransform();
        transform.setIdentity(); // sets safe default values
        transform.setOrigin(new Ammo.btVector3(pos.x, pos.y, pos.z));
        transform.setRotation(new Ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w));
        let motionState = new Ammo.btDefaultMotionState(transform);

        //setup collision box
        let colShape = new Ammo.btBoxShape(new Ammo.btVector3(scale.x * 0.5, scale.y * 0.5, scale.z * 0.5));
        colShape.setMargin(0.05);

        let localInertia = new Ammo.btVector3(0, 0, 0);
        colShape.calculateLocalInertia(mass, localInertia);

        //  provides information to create a rigid body
        let rigidBodyStruct = new Ammo.btRigidBodyConstructionInfo(mass, motionState, colShape, localInertia);
        let body = new Ammo.btRigidBody(rigidBodyStruct);
        body.setFriction(10);
        body.setRollingFriction(10);

        // add to world
        physicsWorld.addRigidBody(body);
    }


    function createBall(){
                
        let pos = {x: 0, y: 4, z: 0};
        let radius = 5;
        let quat = {x: 0, y: 0, z: 0, w: 1};
        let mass = 2;

        

        var marble_loader = new THREE.TextureLoader(manager);
        var marbleTexture = marble_loader.load(earth);
        marbleTexture.wrapS = marbleTexture.wrapT = THREE.RepeatWrapping;
        marbleTexture.repeat.set(1, 1);
        marbleTexture.anisotropy = 1;
        marbleTexture.encoding = THREE.sRGBEncoding;

        //threeJS Section
        let ball = ballObject = new THREE.Mesh(new THREE.SphereBufferGeometry(radius,32,32), new THREE.MeshPhongMaterial({map: marbleTexture}));

        ball.position.set(pos.x, pos.y, pos.z);
        
        ball.castShadow = true;
        ball.receiveShadow = true;

        scene.add(ball);
        


        //Ammojs Section
        let transform = new Ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin( new Ammo.btVector3( pos.x, pos.y, pos.z ) );
        transform.setRotation( new Ammo.btQuaternion( quat.x, quat.y, quat.z, quat.w ) );
        let motionState = new Ammo.btDefaultMotionState( transform );

        let colShape = new Ammo.btSphereShape( radius );
        colShape.setMargin( 0.05 );

        let localInertia = new Ammo.btVector3( 0, 0, 0 );
        colShape.calculateLocalInertia( mass, localInertia );

        let rbInfo = new Ammo.btRigidBodyConstructionInfo( mass, motionState, colShape, localInertia );
        let body = new Ammo.btRigidBody( rbInfo );

        body.setFriction(4);
        body.setRollingFriction(10);

        body.setActivationState( STATE.DISABLE_DEACTIVATION )


        physicsWorld.addRigidBody( body );
        
        ball.userData.physicsBody = body;
        rigidBodies.push(ball);
    }
    
    function createBall2(){
                
        let pos = {x: 0, y: 4, z: 0};
        let radius = 5;
        let quat = {x: 0, y: 0, z: 0, w: 1};
        let mass = 30;

        

        var marble_loader = new THREE.TextureLoader(manager);
        var marbleTexture = marble_loader.load(earth);
        marbleTexture.wrapS = marbleTexture.wrapT = THREE.RepeatWrapping;
        marbleTexture.repeat.set(1, 1);
        marbleTexture.anisotropy = 1;
        marbleTexture.encoding = THREE.sRGBEncoding;

        //threeJS Section
        let ball =  new THREE.Mesh(new THREE.SphereBufferGeometry(radius,32,32), new THREE.MeshPhongMaterial({map: marbleTexture}));

        ball.position.set(pos.x, pos.y, pos.z);
        
        ball.castShadow = true;
        ball.receiveShadow = true;

        scene.add(ball);
        


        //Ammojs Section
        let transform = new Ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin( new Ammo.btVector3( pos.x, pos.y, pos.z ) );
        transform.setRotation( new Ammo.btQuaternion( quat.x, quat.y, quat.z, quat.w ) );
        let motionState = new Ammo.btDefaultMotionState( transform );

        let colShape = new Ammo.btSphereShape( radius );
        colShape.setMargin( 0.05 );

        let localInertia = new Ammo.btVector3( 0, 0, 0 );
        colShape.calculateLocalInertia( mass, localInertia );

        let rbInfo = new Ammo.btRigidBodyConstructionInfo( mass, motionState, colShape, localInertia );
        let body = new Ammo.btRigidBody( rbInfo );

        body.setFriction(4);
        body.setRollingFriction(10);

        body.setActivationState( STATE.DISABLE_DEACTIVATION )


        physicsWorld.addRigidBody( body );
        
        ball.userData.physicsBody = body;
        rigidBodies.push(ball);
    }


    
    function createKinematicBox(){
        
        let pos = {x: 40, y: 6, z: 5};
        let scale = {x: 10, y: 10, z: 10};
        let quat = {x: 0, y: 0, z: 0, w: 1};
        let mass = 1;
        
        //贴图
        const loader = new THREE.TextureLoader(manager);
        const texture = loader.load(boxTexture);
        texture.magFilter = THREE.LinearFilter;
        texture.minFilter = THREE.LinearFilter;
        texture.encoding = THREE.sRGBEncoding;
        const loadedTexture = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            color: 0xffffff,
        });

        var borderMaterial = new THREE.MeshBasicMaterial({
            color: 0x000000,
        });
        borderMaterial.color.convertSRGBToLinear();

        var materials = [
            borderMaterial, // Left side
            borderMaterial, // Right side
            borderMaterial, // Top side   ---> THIS IS THE FRONT
            borderMaterial, // Bottom side --> THIS IS THE BACK
            loadedTexture, // Front side
            borderMaterial, // Back side
        ];

        //threeJS Section
        // new THREE.MeshPhongMaterial({color: 'green'})
        kObject = new THREE.Mesh(new THREE.BoxBufferGeometry(), materials);

        kObject.position.set(pos.x, pos.y, pos.z);
        kObject.scale.set(scale.x, scale.y, scale.z);

        kObject.castShadow = true;
        kObject.receiveShadow = true;
        kObject.userData.URL = 'https://www.baidu.com'

        scene.add(kObject);
        cursorHoverObjects.push(kObject)


        //Ammojs Section
        let transform = new Ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin( new Ammo.btVector3( pos.x, pos.y, pos.z ) );
        transform.setRotation( new Ammo.btQuaternion( quat.x, quat.y, quat.z, quat.w ) );
        let motionState = new Ammo.btDefaultMotionState( transform );

        let colShape = new Ammo.btBoxShape( new Ammo.btVector3( scale.x * 0.5, scale.y * 0.5, scale.z * 0.5 ) );
        colShape.setMargin( 0.05 );

        let localInertia = new Ammo.btVector3( 0, 0, 0 );
        colShape.calculateLocalInertia( mass, localInertia );

        let rbInfo = new Ammo.btRigidBodyConstructionInfo( mass, motionState, colShape, localInertia );
        let body = new Ammo.btRigidBody( rbInfo );

        body.setFriction(4);
        body.setRollingFriction(10);
        
        body.setActivationState( STATE.DISABLE_DEACTIVATION );
        body.setCollisionFlags( FLAGS.CF_KINEMATIC_OBJECT );


        physicsWorld.addRigidBody( body );
        kObject.userData.physicsBody = body;

    }



    function moveBall(){

        let scalingFactor = 20;

        let moveX =  moveDirection.right - moveDirection.left;
        let moveZ =  moveDirection.back - moveDirection.forward;
        let moveY =  0; 

        if( moveX == 0 && moveY == 0 && moveZ == 0) return;

        let resultantImpulse = new Ammo.btVector3( moveX, moveY, moveZ )
        resultantImpulse.op_mul(scalingFactor);

        let physicsBody = ballObject.userData.physicsBody;
        physicsBody.setLinearVelocity( resultantImpulse );

    }

    
    function moveKinematic(){

        let scalingFactor = 0.3;

        let moveX =  kMoveDirection.right - kMoveDirection.left;
        let moveZ =  kMoveDirection.back - kMoveDirection.forward;
        let moveY =  0;


        let translateFactor = tmpPos.set(moveX, moveY, moveZ);

        translateFactor.multiplyScalar(scalingFactor);

        kObject.translateX(translateFactor.x);
        kObject.translateY(translateFactor.y);
        kObject.translateZ(translateFactor.z);
        
        kObject.getWorldPosition(tmpPos);
        kObject.getWorldQuaternion(tmpQuat);

        let physicsBody = kObject.userData.physicsBody;

        let ms = physicsBody.getMotionState();
        if ( ms ) {

            ammoTmpPos.setValue(tmpPos.x, tmpPos.y, tmpPos.z);
            ammoTmpQuat.setValue( tmpQuat.x, tmpQuat.y, tmpQuat.z, tmpQuat.w);

            
            tmpTrans.setIdentity();
            tmpTrans.setOrigin( ammoTmpPos ); 
            tmpTrans.setRotation( ammoTmpQuat ); 

            ms.setWorldTransform(tmpTrans);

        }

    }




    function updatePhysics( deltaTime ){

        // Step world
        physicsWorld.stepSimulation( deltaTime, 10 );

        // Update rigid bodies
        for ( let i = 0; i < rigidBodies.length; i++ ) {
            let objThree = rigidBodies[ i ];
            let objAmmo = objThree.userData.physicsBody;
            let ms = objAmmo.getMotionState();
            if ( ms ) {

                ms.getWorldTransform( tmpTrans );
                let p = tmpTrans.getOrigin();
                let q = tmpTrans.getRotation();
                objThree.position.set( p.x(), p.y(), p.z() );
                objThree.quaternion.set( q.x(), q.y(), q.z(), q.w() );

            }
        }

    }

}