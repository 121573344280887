import React, { useState, useEffect, Fragment } from "react";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import "monaco-editor/esm/vs/basic-languages/html/html.contribution";
import "monaco-editor/esm/vs/language/html/htmlMode";
import "./exEditor.less"
import axios from "axios";
import 'monaco-editor/esm/vs/language/typescript/monaco.contribution';
import 'monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution';


let editorDom
const ExEditor = () => {
    const [editor, setEditor] = useState(null);

    useEffect(() => {
        // 初始化Monaco Editor
        const editor = monaco.editor.create(document.getElementById("container"), {
        value: "<h1>Hello, World!</h1>",
        language: "html",
        automaticLayout: true,
        theme: "vs-dark",
        // esmLoader: () => import("monaco-editor/esm/vs/editor/editor.main")
        });

        setEditor(editor);

        // 监听编辑器内容变化事件
        // editor.onDidChangeModelContent(() => {
        //     console.log('change');
        // });

        axios.get("/ejs_tem/first.ejs").then(res => {
            editor.setValue(res.data)
            const previewEle = document.getElementById('preview')
            previewEle.setAttribute('srcdoc', editor.getValue()) 
        })
        
        return () => {
            // 销毁编辑器
            editor.dispose();
        };
    }, []);
  
    const startRun = () => {
        const previewEle = document.getElementById('preview')
        previewEle.setAttribute('srcdoc', editor.getValue()) 
    }

  return (
    
    <Fragment>
    <div className={"luy-out"}>
        <div className="left-content">
            <div className="header-tool">
                <div className="start-btn" onClick={() => startRun()}>运行</div>
            </div>
            <div id="container"></div>
        </div>
        <div className={"view-content"}>
            <iframe id="preview"></iframe>
        </div>
    </div>
</Fragment>
  );
};

export default ExEditor;
