// import line_satellite from "../../common/images/line_satellite.png"
const Cesium = window.Cesium
const EmissionSource = `
czm_material czm_getMaterial(czm_materialInput materialInput)
 {
    czm_material material = czm_getDefaultMaterial(materialInput);
    vec2 st = materialInput.st;
    vec4 colorImage = texture2D(image, st);
    material.alpha = colorImage.a * st.s * color.a;
    material.diffuse = colorImage.rgb * color.rgb;
    material.emission = (colorImage.rgb * color.rgb) * 2.5;
    return material;
  }
`
class EmissionMaterial {
  constructor (color) {
    this._definitionChanged = new Cesium.Event()
    this._colorSubscription = undefined
    this.color = color
    this.type = 'EmissionLine'
    Cesium.Material._materialCache.addMaterial('EmissionLine', {
      fabric: {
        type: 'EmissionLine',
        uniforms: {
          color: new Cesium.Color(1.0, 1.0, 1.0, 1.0),
          image: ''
        },
        source: EmissionSource
      },
      translucent: function () {
        return true
      }
    })
  }
  get isConstant () {
    return false
  }
  get definitionChanged () {
    return this._definitionChanged
  }
  equals () {
    return false
  }
  getType () {
    return this.type
  }
  getValue (time, result) {
    if (!Cesium.defined(result)) {
      result = {}
    }
    result.color = this.color
    result.image = require('../../common/images/line_satellite.png')
    return result
  }
}
export default EmissionMaterial
