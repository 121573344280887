const satelliteInfo = [
  {
    name: 'aqua',
    id: 'AQUA',
    path: 'czml/aqua.czml',
    color: '#F64B32',
    modelUrl: 'satellite_gltf/Aqua/Aqua.gltf'
  },
  {
    name: 'cryosat-2',
    id: 'CRYOSAT 2',
    path: 'czml/cryosat-2.czml',
    color: '#00C0FF',
    modelUrl: 'satellite_gltf/CYGNSS/CYGNSS.gltf'
  },
  {
    name: 'fengyun-3a',
    id: 'FENGYUN 3A',
    path: 'czml/fengyun-3a.czml',
    color: '#DFAE26',
    modelUrl: 'satellite_gltf/CYGNSS/CYGNSS.gltf'
  },
  {
    name: 'fengyun-3b',
    id: 'FENGYUN 3B',
    path: 'czml/fengyun-3b.czml',
    color: '#F64B32',
    modelUrl: 'satellite_gltf/CYGNSS/CYGNSS.gltf'
  },
  {
    name: 'fengyun-3c',
    id: 'FENGYUN 3C',
    path: 'czml/fengyun-3c.czml',
    color: '#00C0FF',
    modelUrl: 'satellite_gltf/CYGNSS/CYGNSS.gltf'
  },
  {
    name: 'fengyun-3d',
    id: 'FENGYUN 3D',
    path: 'czml/fengyun-3d.czml',
    color: '#DFAE26',
    modelUrl: 'satellite_gltf/CYGNSS/CYGNSS.gltf'
  },
  {
    name: 'gcom-w1',
    id: 'GCOM-W1 (SHIZUKU)',
    path: 'czml/gcom-w1.czml',
    color: '#F64B32',
    modelUrl: 'satellite_gltf/CYGNSS/CYGNSS.gltf'
  },
  {
    name: 'icesat-2',
    id: 'ICESAT-2',
    path: 'czml/icesat-2.czml',
    color: '#DFAE26',
    modelUrl: 'satellite_gltf/ICESat/ICESat.gltf'
  },
  {
    name: 'landsat-7',
    id: 'LANDSAT 7',
    path: 'czml/landsat-7.czml',
    color: '#F64B32',
    modelUrl: 'satellite_gltf/Landsat/Landsat.gltf'
  },
  {
    name: 'landsat-8',
    id: 'LANDSAT 8',
    path: 'czml/landsat-8.czml',
    color: '#00C0FF',
    modelUrl: 'satellite_gltf/Landsat/Landsat.gltf'
  },
  {
    name: 'sentinel-1a',
    id: 'SENTINEL-1A',
    path: 'czml/sentinel-1a.czml',
    color: '#F64B32',
    modelUrl: 'satellite_gltf/Sentinel/Sentinel.gltf'
  },
  {
    name: 'sentinel-1b',
    id: 'SENTINEL-1B',
    path: 'czml/sentinel-1b.czml',
    color: '#DFAE26',
    modelUrl: 'satellite_gltf/Sentinel/Sentinel.gltf'
  },
  {
    name: 'sentinel-5p',
    id: 'SENTINEL-5P',
    path: 'czml/sentinel-5p.czml',
    color: '#F64B32',
    modelUrl: 'satellite_gltf/Sentinel/Sentinel.gltf'
  },
  {
    name: 'smap',
    id: 'SMAP',
    path: 'czml/smap.czml',
    color: '#00C0FF',
    modelUrl: 'satellite_gltf/SMAP/Smap.gltf'
  },
  {
    name: 'terra',
    id: 'TERRA',
    path: 'czml/terra.czml',
    color: '#DFAE26',
    modelUrl: 'satellite_gltf/Terra/Terra.gltf'
  }
]
const tileImage = [
  'tileImages/LC08_L1TP_116036_20211008_20211018_01_T1.png',
  'tileImages/LC08_L1TP_116037_20211008_20211018_01_T1.png',
  'tileImages/LC08_L1GT_116038_20211008_20211018_01_T2.png',
  'tileImages/LC08_L1GT_116039_20211008_20211018_01_T2.png',
  'tileImages/LC08_L1GT_116040_20211008_20211018_01_T2.png',
  'tileImages/LC08_L1GT_116041_20211008_20211018_01_T2.png',
  'tileImages/LC08_L1GT_116042_20211008_20211018_01_T2.png',
  'tileImages/LC08_L1TP_116043_20.211008_20211018_01_T1.png',
  'tileImages/LC08_L1TP_116046_20211008_20211018_01_T1.png',
  'tileImages/LC08_L1TP_116047_20211008_20211018_01_T1.png',
  'tileImages/LC08_L1GT_116048_20211008_20211018_01_T2.png',
  'tileImages/LC08_L1TP_116049_20211008_20211018_01_T1.png'
]
export {satelliteInfo, tileImage}
